import { Link } from "gatsby";
import React from "react";
import { formatDate } from "../utils/formatDate";
import Tag from "./tag";

interface Props {
  data: {
    tags: Array<string>;
    uid: string;
    data: {
      header_image: { url: string };
      date: string;
      updatedDate?: Date;
      author: { text: string };
      title: { text: string };
    };
  };
}

const ArticlePreviewOverlaidText = ({ data }: Props) => {
  const date = new Date(data.data.date);
  return (
    <Link
      className="article-preview overlaid-text text-decoration-none"
      to={`/articles/${data.uid}`}
    >
      <div
        className="img-div"
        style={{ backgroundImage: `url(${data.data.header_image.url})` }}
      >
        <div className="text-div">
          <div className="date-author-div">
            <p>
              {formatDate(date)}{" "}
              {data.data.updatedDate ? (
                <i>Updated: {formatDate(data.data.updatedDate)}</i>
              ) : (
                ""
              )}
            </p>
            <p className="divider">|</p>
            <p>{data.data.author.text}</p>
          </div>
          <h2>{data.data.title.text}</h2>
          <div className="category-container">
            {data.tags.map((tag) => (
              <Tag text={tag} key={tag} />
            ))}
          </div>
        </div>
      </div>
    </Link>
  );
};

export default ArticlePreviewOverlaidText;
